<template>
  <div class>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="7" style="position: relative">
          <div class="pr-1">
            <v-row class="d-flex">
              <v-col cols="12" md="7" class="pb-0">
                <v-row style="flex-wrap: inherit; max-width: 100%">
                  <v-combobox
                    label="Search"
                    outlined
                    flat
                    dense
                    :items="item"
                    :filter="customFilter"
                    item-text="item"
                    item-value="item"
                    style="font-size: 14px"
                    class="pl-3 pt-4 pb-0 mb-0 font-weight-light text-sm-left"
                    v-model="searchBox"
                    :return-object="false"
                    ref="searchComboBox"
                    @mouseover="searchBoxShow"
                    @mouseleave="searchBoxoff"
                    @keyup.enter="initialGetST()"
                  >
                  </v-combobox>

                  <v-btn
                    elevation="0"
                    height="32px"
                    min-width="37px"
                    class="ml-1 mt-4 border"
                    style="border-color: #999 !important; width: 30px"
                    color="blue-grey lighten-5"
                    @click="initialGetST()"
                  >
                    <v-icon size="175%"> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    elevation="0"
                    height="32px"
                    min-width="37px"
                    class="ml-1 mt-4 border"
                    style="border-color: #999 !important; padding: 0px !important;"
                    color="blue-grey lighten-5"
                    @click="resetSearch"
                    small
                    v-if="searchBox != ''"
                  >
                    <v-icon color="green"> mdi-minus-circle </v-icon>
                  </v-btn>
                  <v-btn
                    elevation="0"
                    height="32px"
                    min-width="32px"
                    class="ml-1 mt-4 border"
                    style="border-color: #999 !important; padding: 0px !important;"
                    color="blue-grey lighten-5"
                    small
                    @click="showHelp"
                  >
                  <v-icon size="175%"> mdi-help</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" md="5" class="pb-0" v-if="searchBox != ''">
                <v-row class="ml-n4 pr-2 pt-3 pb-0 mb-2 justify-end">
                  <div class="pa-2">
                    <h4
                      class="font-weight-regular"
                      @mouseover="searchResultsShow"
                      @mouseleave="searchResultsoff"
                    >
                      <v-icon class="pr-1">mdi-format-list-bulleted</v-icon
                      >{{ totalHostsLabel }} RESULTS
                    </h4>
                  </div>

                  <v-btn
                    x-small
                    height="26px"
                    min-width="26px"
                    class="pa-0 rounded-0 mt-1"
                    elevation="0"
                    style="border: 1px solid gainsboro; background-color: white"
                    @click="scrollDevices('start')"
                    :disabled="disableHostScroll"
                    @mouseover="searchResultsShow"
                    @mouseleave="searchResultsoff"
                  >
                    <v-icon size="220%">mdi-chevron-double-left</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    height="26px"
                    min-width="26px"
                    class="pa-0 rounded-0 mt-1"
                    elevation="0"
                    style="border: 1px solid gainsboro; background-color: white"
                    @click="scrollDevices('previous')"
                    :disabled="disableHostScroll"
                    @mouseover="searchResultsShow"
                    @mouseleave="searchResultsoff"
                  >
                    <v-icon size="220%">mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    height="26px"
                    min-width="18%"
                    class="pa-0 rounded-0 mt-1 text-center text-xl-body-2"
                    elevation="0"
                    style="border: 1px solid gainsboro; background-color: white"
                    @mouseover="searchResultsShow"
                    @mouseleave="searchResultsoff"
                  >
                    {{ numberFormat(scroll.from) }} -
                    {{
                      hosts.length == 10
                        ? numberFormat(scroll.from + 9)
                        : totalHostsLabel
                    }}
                  </v-btn>
                  <v-btn
                    x-small
                    height="26px"
                    min-width="26px"
                    class="pa-0 rounded-0 mt-1"
                    elevation="0"
                    style="border: 1px solid gainsboro; background-color: white"
                    @click="scrollDevices('next')"
                    :disabled="disableHostScroll"
                    @mouseover="searchResultsShow"
                    @mouseleave="searchResultsoff"
                  >
                    <v-icon size="220%">mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    height="26px"
                    min-width="26px"
                    class="pa-0 rounded-0 mt-1"
                    elevation="0"
                    style="border: 1px solid gainsboro; background-color: white"
                    @click="scrollDevices('end')"
                    :disabled="disableHostScroll"
                    @mouseover="searchResultsShow"
                    @mouseleave="searchResultsoff"
                  >
                    <v-icon size="220%">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-divider
            class="mb-2 mt-1"
            style="color: #d3d4e6"
            v-if="searchBox != ''"
          ></v-divider>
          <!-- <div v-if="searchBox === ''" style="padding: 20px 0px">
            동영상 삽입
            <iframe width="600" height="400" src="https://www.youtube.com/embed/8FPzKfD36vc?si=6feUnjGzZEyy62FP"  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            
          </div> -->

          <div
            class="no-scroll"
            v-bind:class="{ 'card-section': !displayDialog }"
            v-if="searchBox != ''"
          >
            <v-alert
              dense
              border="left"
              dismissible
              type="warning"
              v-model="alert"
            >
              <span class="font-weight-bold">WARNING: </span>option &lsquo;{{
                searchBox
              }}&rsquo; not understood.
            </v-alert>

            <v-alert
              dense
              border="left"
              dismissible
              type="warning"
              v-model="alert_1"
            >
              <span class="font-weight-bold">WARNING: </span>option &lsquo;{{
                searchBox
              }}&rsquo; must satisfiy the following format.<br />
              {{ searchKeywordTitle }}<br />
              <span class="font-weight-bold">Ex&#41;</span>
              {{ searchKeywordEx }}
            </v-alert>

            <v-alert
              dense
              border="left"
              dismissible
              type="warning"
              v-model="alert_2"
            >
              <span class="font-weight-bold">WARNING: </span> ; is not allowed
              in SearchBox. You should use :
            </v-alert>

            <v-alert
              dense
              border="left"
              dismissible
              type="warning"
              v-model="alert_3"
            >
              <span class="font-weight-bold">WARNING: </span> ' ' , is not
              allowed in SearchBox.
            </v-alert>

            <v-card
              v-if="hosts.length == 0 && !hostsLoading"
              class="pa-2 px-4 my-4"
              color="grey lighten-5"
              elevation="1"
              outlined
              style="border: 1px black solid !important"
            >
              <div class="d-flex py-5">
                <div class="ma-auto text-center">
                  <div class="mb-2">No results found for search parameters</div>
                  <v-btn
                    outlined
                    color="blue darken-4"
                    elevation="1"
                    @click="resetSearch"
                  >
                    <v-icon class="pr-1">mdi-minus-circle</v-icon>
                    CLEAR
                  </v-btn>
                </div>
              </div>
            </v-card>

            <div v-if="hostsLoading">
              <v-card
                class="mb-2"
                v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :key="i"
              >
                <v-skeleton-loader type="article"></v-skeleton-loader>
              </v-card>
            </div>

            <host-card
              class="mb-2 clickable"
              v-for="(host, index) in hosts"
              :key="host.geoip.ip + index"
              :host="host"
              :selected="
                selectedHost.geoip.ip == host.geoip.ip &&
                selectedHost['xml-nmap'].host[0].starttime ==
                  host['xml-nmap'].host[0].starttime
              "
              style="border: 1px solid gainsboro !important"
              v-on:click.native="
                selectedId = ids[index];
                selectedTraceObj=traceObjs[index];
                selectedHost = host;
                dialog = displayDialog;
                selectedTab = 's';
              "
            >
            </host-card>
          </div>
        </v-col>
        <v-col col="12" md="5" v-if="selectedHost != null && searchBox != ''">
          <v-card
            style="border: 1px solid gainsboro"
            elevation="1"
            outlined
            class="d-none d-md-block"
          >
            <v-tabs
              color="grey darken-3"
              height="42px"
              :key="'t' + hideVuln"
              v-model="selectedTab"
            >
              <v-tab
                class="font-weight-bold"
                key="s"
                @mouseover="s_summaryShow"
                @mouseleave="s_summaryoff"
                >SUMMARY</v-tab
              >
              <v-tab
                class="font-weight-bold"
                v-show="!hideVuln"
                key="v"
                @mouseover="s_vulnShow"
                @mouseleave="s_vulnoff"
                >VULN. SCAN</v-tab
              >
              <v-tab
                class="font-weight-bold"
                key="o"
                v-if="true"
                @mouseover="s_osintShow"
                @mouseleave="s_osintoff"
                >OSINT SCAN</v-tab
              >
              <!-- <v-tab
                class="font-weight-bold"
                v-show="!hideVuln"
                @mouseover="s_traceShow"
                @mouseleave="s_traceoff"
                key="ttt"
                >TRACEROUTE</v-tab
              >
              <v-tab
                class="font-weight-bold"
                v-show="!hideVuln"
                @mouseover="s_gptShow"
                @mouseleave="s_gptoff"
                key="gpt"
                >Lab-Report</v-tab
              > -->

              <v-tab-item class="detail-section no-scroll" key="s">
                <Summary
                  v-if="!hostsLoading"
                  :host="selectedHost"
                  :key="
                    selectedHost.geoip.ip +
                    selectedHost['xml-nmap'].host[0].starttime
                  "
                ></Summary>
              </v-tab-item>

              <v-tab-item class="detail-section no-scroll" key="v">
                <VULNSCAN
                  v-if="!hostsLoading && !hideVuln"
                  :host="selectedHost"
                  :key="
                    selectedHost.geoip.ip +
                    selectedHost['xml-nmap'].host[0].starttime
                  "
                ></VULNSCAN>
              </v-tab-item>

              <v-tab-item class="detail-section no-scroll" key="o">
                <OSINTSCAN
                  v-if="!hostsLoading"
                  :threat="selectedHost.threat"
                  :key="
                    selectedHost.geoip.ip +
                    selectedHost['xml-nmap'].host[0].starttime
                  "
                ></OSINTSCAN>
              </v-tab-item>

              <!-- <v-tab-item class="detail-section no-scroll" key="ttt">
                <TRACE
                v-if="!hostsLoading && !hideVuln"
                :selectedTraceObj=selectedTraceObj
                :key=selectedTraceObj.id
                >
                </TRACE>
              </v-tab-item>

              <v-tab-item class="detail-section no-scroll" key="gpt">
                <GPT
                  v-if="!hostsLoading && !hideVuln"
                  :selectedId=selectedId
                  :key=selectedId
                ></GPT>
              </v-tab-item> -->

            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="selectedHost != null">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar light color="blue-grey lighten-4" dense>
              <v-spacer></v-spacer>
              <v-btn icon light @click="dialog = false" color="black">
                <v-icon>mdi-close</v-icon>
                Close
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-tabs color="grey darken-3" centered height="42px">
              <!-- ms = mobile  -->
              <v-tab class="font-weight-bold" key="ms">SUMMARY</v-tab>
              <v-tab class="font-weight-bold" v-show="!hideVuln" key="mv"
                >VULN. SCAN</v-tab
              >
              <v-tab class="font-weight-bold" key="mo">OSINT SCAN</v-tab>
              <!-- <v-tab class="font-weight-bold" v-show="!hideVuln" key="ttt">TRACEROUTE</v-tab>
              <v-tab class="font-weight-bold" v-show="!hideVuln" key="gpt">Lab-Report</v-tab> -->

              <v-tab-item key="ms">
                <Summary
                  v-if="!hostsLoading"
                  :host="selectedHost"
                  :key="selectedHost.geoip.ip"
                ></Summary>
              </v-tab-item>

              <v-tab-item key="mv">
                <VULNSCAN
                  v-if="!hostsLoading && !hideVuln"
                  :host="selectedHost"
                  :key="selectedHost.geoip.ip"
                ></VULNSCAN>
              </v-tab-item>

              <v-tab-item key="mo">
                <OSINTSCAN
                  v-if="!hostsLoading"
                  :threat="selectedHost.threat"
                  :key="selectedHost.geoip.ip"
                ></OSINTSCAN>
              </v-tab-item>

              <!-- <v-tab-item key="ttt">
                <TRACE
                v-if="!hostsLoading && !hideVuln"
                :selectedTraceObj=selectedTraceObj
                :key=selectedTraceObj.id
                >
                </TRACE>
              </v-tab-item>

              <v-tab-item key="gpt">
                <GPT
                  v-if="!hostsLoading && !hideVuln"
                  :selectedId=selectedId
                  :key=selectedId
                ></GPT>
              </v-tab-item> -->

            </v-tabs>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- <v-btn
        elevation="2"
        icon
        dark
        bottom
        right
        fixed
        style="position: absolute; bottom: 20px; right: 20px; background-color: #B0BEC5; width: 40px; height: 40px;"
      >
        <img src="@/assets/help.svg" class="whiteBtn" style="width: 24px; height: 24px;">
      </v-btn> -->
      <!-- <div v-if=true>
        <search-help></search-help>
      </div> -->
    </v-container>
  </div>
</template>

<script>
import Summary from "@/components/search/Summary.vue";
import HostCard from "@/components/search/HostCard.vue";
import OSINTSCAN from "@/components/search/OSINTDetails.vue";
import EventBus from "@/plugins/EventBus.js";
import VULNSCAN from "@/components/search/VULNDetails.vue";
// import SearchHelp from '../components/search/searchHelp.vue';
// import TRACE from "@/components/search/TRACERouting.vue";
// import GPT from "@/components/search/gptDetails.vue";
import axios from "axios";
import { index } from "@/mixins/elastic";
import exHelp_examples from "@/components/help/help_examples.json";

import { functions, PTestConn } from "@/mixins/commons";
import {
  searchCommandKeyword,
  searchTemplates,
  search,
  searchCommandKeywordScroll,
} from "@/mixins/queries";

export default {
  name: `Search`,
  data: () => ({
    examples:exHelp_examples.exTitle,
    winRef :null,
    fab: false,
    alert: false,
    alert_1: false,
    alert_2: false,
    alert_3: false,
    dialog: false,
    selectedTab: "s",
    totalHosts: 0,
    totalHostsLabel: 0,
    hosts: [],
    ids:[],
    traceObjs:[],
    hostsLoading: true,
    selectedHost: null,
    selectedId:null,
    selectedTraceObj:null,
    searchKeywordTitle: "",
    searchKeywordEx: "",
    item: [
      "ip:[string](,[string],[string],...)",
      "hostname:[string](,[string],[string],...)  (* wildcard)",
      "domain:[FQDN]  (* wildcard)",
      "country:[2-letter country code]",
      "city:[string](,[string],[string],...)  (* wildcard)",
      "service:[service name](:[port number])  (* wildcard)",
      "product:[service name]:[product name](:[port number])  (* wildcard)",
      "version:[service name]:[product name]:[service version](:[service port])  (* wildcard)",
      "script:[script id](:[script output])  (* wildcard)",
      "anonftp",
      "anonldap",
      "banner:[string]  (* wildcard)",
      "cookie:[string]  (* wildcard)",
      "files:[string]  (* wildcard)",
      "httptitle:[string]  (* wildcard)",
      "httphdr",
      "owa",
      "phpmyadmin",
      "smb_dnsdomain:[string]  (* wildcard)",
      "smb_fqdn:[string]  (* wildcard)",
      "x11open",
      "os:[string]  (* wildcard)",
      "devtype:[string]  (* wildcard)",
      "netdev",
      "phonedev",
      "cpe:[string]  (* wildcard)",
      "tcp:[string](,[string],[string],...)",
      "openport",
      "webfiles:[string]  (* wildcard)",
      "asnum:[string](,[string],[string],...)",
      "asname:[string](,[string],[string],...)  (* wildcard)",
      "scorelevel:[scorelevel]  (! scorelevel:critical/high/medium/low)",
      "scorepoint:[OP][number]",
      "vscorelevel:[scorelevel]  (! scorelevel:critical/high/medium/low)",
      "vscorepoint:[OP][number]",
      "tscorelevel:[scorelevel]  (! scorelevel:critical/high/medium/low)",
      "tscorepoint:[OP][number]",
      "scantime:[OP][number]",
      "tcurrentblack",
      "tblack",
      "tcategory:[string](,[string],[string],...)  (* wildcard)",
      "tdetect:[OP][number]",
      "tsource:[string]  (* wildcard)",
      "afp",
      "ajp",
      "bitcoin",
      "cics",
      "citrix",
      "dns",
      "ftp",
      "hadoop",
      "hbase",
      "http",
      "ldap",
      "mssql",
      "db2",
      "mongodb",
      "mysql",
      "oracle",
      "redis",
      "vnc",
      "govdomain",
      "cve:[cve-id]  (* wildcard)",
      "date:[date-number]  (! d-day, w-week, M-month, y-year)",
      "cycle:[string]",
      "net:[start ip-end ip]",
    ].sort(),

    commands: Object.keys(searchTemplates.commands).sort(),

    searchBox: ``,

    scroll: {
      from: 1,
      nextDevices: [],
      previousDevices: [],
    },
  }),

  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  components: {
    Summary,
    HostCard,
    OSINTSCAN,
    VULNSCAN,
    // SearchHelp
    // TRACE,
    // GPT
  },
  methods: {
    showHelp:function(){
      if(this.winRef  ==null){
        this.winRef =window.open('#/searchHelp', '_blank', 'top=140, left=300, width=1000, height=600, menubar=no, toolbar=no, location=no, directories=no, status=no, scrollbars=no, copyhistory=no, resizable=no');
      }else{
        if(this.winRef.closed ==false){
          this.winRef.focus();
        }else{
          this.winRef =window.open('#/searchHelp', '_blank', 'top=140, left=300, width=1000, height=600, menubar=no, toolbar=no, location=no, directories=no, status=no, scrollbars=no, copyhistory=no, resizable=no');
        }
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 0;
    },

    searchBoxShow: function () {
      EventBus.$emit(
        "searchBoxShow",
        `You can search for IP device(s) that meet specific search criteria from the scan results so far by using various search terms, and refer to the HELP menu for usage of about 60 various search keywords.`
      );
    },
    searchBoxoff: function () {
      EventBus.$emit("searchBoxShow", null);
    },
    searchResultsShow: function () {
      EventBus.$emit(
        "searchResultsShow",
        `The number means the total number of IP devices that match the search criteria you entered, and you can move the pages using the navigation bar.`
      );
    },
    searchResultsoff: function () {
      EventBus.$emit("searchResultsShow", null);
    },
    s_summaryShow: function () {
      EventBus.$emit(
        "s_summaryShow",
        `This tab displays general information such as the geographic location of the device along with a map, and it shows a summary of the Vulnerability scan results and Threat scan results.`
      );
    },
    s_summaryoff: function () {
      EventBus.$emit("s_summaryShow", null);
    },
    s_vulnShow: function () {
      EventBus.$emit(
        "s_vulnShow",
        `This tab shows the detailed results of Vulnerability scan for the device, and if there is no port in the open state, this tab is not shown.`
      );
    },
    s_vulnoff: function () {
      EventBus.$emit("s_vulnShow", null);
    },
    s_osintShow: function () {
      EventBus.$emit(
        "s_osintShow",
        `This tab shows the detailed results of OSINT threat scan for the device, such as the number of detections by 8 threat DB types and the current blacklist status and so on.`
      );
    },
    s_osintoff: function () {
      EventBus.$emit("s_osintShow", null);
    },

    s_traceShow:function(){
      EventBus.$emit(
        "s_traceShow",
        `Through this tab, you can view the routing paths that packets traverse from the scanner to the target IP and the Time to Live (TTL) values for each routing node.`
      );
    },
    s_traceoff:function(){
      EventBus.$emit("s_traceShow", null);
    },

    s_gptShow:function(){
      EventBus.$emit(
        "s_gptShow",
        `This tab features an experimental report function utilizing GenAI. GenAI summarizes the vulnerabilities and risks present in the device and suggests measures for enhancing security.`
      );
    },
    s_gptoff:function(){
      EventBus.$emit("s_gptShow", null);
    },

    handleInput(newData) {
      this.$refs.searchComboBox.blur();
      this.$nextTick(() => {
        this.$refs.searchComboBox.blur();
        if (newData && newData.value) {
          this.searchBox = newData.value;
        } else if (newData) {
          this.searchBox = newData;
        } else {
          this.searchBox = null;
        }
      });
    },
    numberFormat(number) {
      return new Intl.NumberFormat().format(number);
    },
    resetSearch() {
      this.searchBox = ``;
      this.initialGetST();
    },
    customFilter(item, queryText) {
      const text = item.toLowerCase();
      let words = queryText.split(" ");
      let currentWord = words[words.length - 1];
      const searchText = currentWord.toLowerCase();
      return text.indexOf(searchText) > -1;
    },
    initialGetST: function (order = `desc`) {
      this.$refs.searchComboBox.blur();
      let self = this;
      this.$nextTick().then(function () {
        self.hosts = [];
        self.selectedHost = null;
        self.hostsLoading = true;
        self.alert = false;
        self.alert_2 = false;
        self.alert_3 = false;
        self.searchKeywordTitle = "";
        self.searchKeywordEx = "";

        let query = searchCommandKeyword(order);

        if (self.searchBox != "") {
          const res = self.searchST();
          res.forEach((command) => {
            if (searchTemplates.commands[command.key]) {
              if (searchTemplates.commands[command.key].type == `noparam`) {
                query[command.key] = "true";
              } else if (
                searchTemplates.commands[command.key].type == `simple`
              ) {
                // query[command.key] = command.value;
                if (command.key == "cycle") {
                  if (Number(command.value).toString() != command.value) {
                    let explain = {};
                    let arrEx = exHelp_examples.exTitle[0].ex;
                    for (let i = 0; i < arrEx.length; i++) {
                      // console.log(arrEx[i].title);
                      if (arrEx[i].title.includes(command.key)) {
                        explain = arrEx[i];
                      }
                    }
                    self.searchKeywordTitle = explain.title;
                    self.searchKeywordEx = explain.example;
                    self.alert_1 = true;
                  } else {
                    query[command.key] = command.value;
                  }
                }
                query[command.key] = command.value;
              }
            } else {
              if (self.searchBox.includes(";")) {
                0;
                self.alert = false;
              }

              if (self.searchBox.includes(`;`)) {
                self.alert_2 = true;
                self.alert = false;
              }
              if (self.searchBox.includes('"${temp}"')) {
                // console.log("success")
              }
              if (self.searchBox.includes("'")) {
                self.alert_3 = true;
              }
              self.alert_1 = false;
              self.alert = true;
              // console.log(self.item.find(e=>e.includes(command.key)));
            }
          });
        }
        // console.log('queryyyyyy:',query);
        axios
          .post(PTestConn, {
            queryurl: index.nmap + "/_search/template",
            querybody: JSON.stringify(query),
          })
          .then((res) => {
            if (res.data.hits.hits.length == 0) self.totalHostsLabel = 0;
            if (res.data.hits.hits.length > 0) {
              self.hosts = res.data.hits.hits.map((x) => {
                return x._source;
              });
              self.ids = res.data.hits.hits.map((x)=>{
                return x._id;
              });
              self.traceObjs=res.data.hits.hits.map((x)=>{
                var id=x._id;
                var endtime=x._source['xml-nmap'].host[0].starttime;
                var starttime=x._source['xml-nmap'].host[0].starttime;
                var port='';
                var proto='';
                var scannerHostname='';
                if(x._source['xml-nmap'].host[0].trace===undefined){
                  port='-';
                  proto='-';
                }else{
                  if(x._source['xml-nmap'].host[0].trace[0].port===undefined){
                    port='-';
                  }else{
                    port=x._source['xml-nmap'].host[0].trace[0].port;
                  }
                  if(x._source['xml-nmap'].host[0].trace[0].proto===undefined){
                    proto='-'
                  }else{
                    proto=x._source['xml-nmap'].host[0].trace[0].proto;
                  }
                }
                if(x._source['xml-nmap'].scanner_hostname===undefined){
                  scannerHostname='-';
                }else{
                  scannerHostname=x._source['xml-nmap'].scanner_hostname;
                }
                var obj={id:id, endtime:endtime, starttime:starttime, port:port, proto:proto, scannerHostname:scannerHostname};
                return obj;
              });
              console.log(self.traceObjs);
              console.log(self.hosts)
              self.selectedHost = self.hosts[0]; 
              self.selectedId = self.ids[0];
              self.selectedTraceObj=self.traceObjs[0];
              console.log(self.selectedTraceObj);
              console.log(self.selectedId);
              self.totalHosts = res.data.hits.total.value;
              self.totalHostsLabel = functions.digits(self.totalHosts);

              self.scroll.previousDevices = [
                res.data.hits.hits[0].sort[0],
                res.data.hits.hits[0]._id,
              ];
              if (res.data.hits.hits.length >= 10)
                self.scroll.nextDevices = [
                  res.data.hits.hits[9].sort[0],
                  res.data.hits.hits[9]._id,
                ];

              self.scroll.endtDevices = [
                res.data.hits.total.value - 10,
                res.data.hits.hits[res.data.hits.hits.length - 1]._id,
              ];
            }
          })
          .catch(function (error) {
            self.$store.commit("triggerTestError", error);
          })
          .finally(() => {
            if (order === `asc`) {
              self.scroll.from = self.totalHosts - 9;
              if (self.scroll.from < 0) {
                self.scroll.from = 0;
              }
            } else if (order === `desc`) {
              self.scroll.from = 1;
            }
            self.hostsLoading = false;
          });
      });
    },

    getDevicesST: function (searchAfter, order = `desc`) {
      this.hostsLoading = true;
      let self = this;
      const query = searchCommandKeywordScroll(order, searchAfter);
      if (self.searchBox != "") {
        const res = self.searchST();
        res.forEach((command) => {
          if (searchTemplates.commands[command.key]) {
            if (searchTemplates.commands[command.key].type == `noparam`) {
              query[command.key] = "true";
            } else if (searchTemplates.commands[command.key].type == `simple`) {
              query[command.key] = command.value;
            }
          } else {
            if (self.searchBox.includes(";")) {
              self.alert_2 = true;
              self.alert = false;
            }
            if (self.searchBox.includes('"')) {
              self.alert_3 = true;
            }
            if (self.searchBox.includes("'")) {
              self.alert_3 = true;
            }
            if (self.searchST.includes(`;`)) {
              self.alert_2 = true;
              self.alert = false;
            }
            if (self.searchST.includes('"')) {
              self.alert_3 = true;
            }
            if (self.searchST.includes("'")) {
              self.alert_3 = true;
            }
            self.alert = true;
          }
        });
      }
      axios
        .post(PTestConn, {
          queryurl: index.nmap + "/_search/template",
          querybody: JSON.stringify(query),
        })
        .then((response) => {
          if (response.data.hits.hits.length == 0) self.totalHostsLabel = 0;
          if (response.data.hits.hits.length > 0) {
            self.selectedHost = self.hosts[0];
            console.log("selected");
            self.totalHosts = response.data.hits.total.value;
            self.totalHostsLabel = functions.digits(self.totalHosts);
            if (order == `asc`) {
              response.data.hits.hits = response.data.hits.hits.sort(function (
                a,
                b
              ) {
                return b.sort[0] - a.sort[0];
              });
            }
            this.hosts = response.data.hits.hits.map((x) => {
              return x._source;
            });
            this.ids = response.data.hits.hits.map((x) => {
              return x._id;
            });
            self.traceObjs=response.data.hits.hits.map((x)=>{
              var id=x._id;
              var endtime=x._source['xml-nmap'].host[0].starttime;
              var starttime=x._source['xml-nmap'].host[0].starttime;
                var port='';
                var proto='';
                var scannerHostname='';
                if(x._source['xml-nmap'].host[0].trace===undefined){
                  port='-';
                  proto='-';
                }else{
                  if(x._source['xml-nmap'].host[0].trace[0].port===undefined){
                    port='-';
                  }else{
                    port=x._source['xml-nmap'].host[0].trace[0].port;
                  }
                  if(x._source['xml-nmap'].host[0].trace[0].proto===undefined){
                    proto='-'
                  }else{
                    proto=x._source['xml-nmap'].host[0].trace[0].proto;
                  }
                }
                if(x._source['xml-nmap'].scanner_hostname===undefined){
                  scannerHostname='-';
                }else{
                  scannerHostname=x._source['xml-nmap'].scanner_hostname;
                }
              var obj={id:id, endtime:endtime, starttime:starttime, port:port, proto:proto, scannerHostname:scannerHostname};
              return obj;
            });
            console.log(self.traceObjs);
            this.selectedHost = this.hosts[0];
            this.selectedId = this.ids[0];
            this.selectedTraceObj= this.traceObjs[0];
            

            this.scroll.previousDevices = [
              response.data.hits.hits[0].sort[0],
              response.data.hits.hits[0]._id,
            ];
            if (response.data.hits.hits.length >= 10)
              this.scroll.nextDevices = [
                response.data.hits.hits[9].sort[0],
                response.data.hits.hits[9]._id,
              ];
          } else {
            this.hostsLoading = false;
          }
        })
        .finally(() => {
          self.hostsLoading = false;
        });
    },

    scrollDevices: function (step) {
      this.selectedTab = "s";
      switch (step) {
        case `next`:
          if (this.scroll.from <= this.totalHosts - 10) {
            this.scroll.from += 10;
            this.getDevicesST(this.scroll.nextDevices, `desc`);
            console.log("next getdevvv");
          } else {
            this.scroll.from = this.totalHosts - 10;
            this.initialGetST(`asc`);
            console.log("next inital");
          }
          break;
        case `previous`:
          if (this.scroll.from > 11) {
            this.scroll.from -= 10;
            this.getDevicesST(this.scroll.previousDevices, `asc`);
            console.log("get previ");
          } else {
            this.scroll.from = 1;
            this.initialGetST(`desc`);
            console.log("inital previ");
          }
          break;
        case `start`:
          this.scroll.from = 1;
          this.initialGetST(`desc`);
          console.log("start");
          break;
        case `end`:
          this.initialGetST(`asc`);
          console.log("end");
          break;
        default:
          break;
      }
    },
    search: function () {
      this.alert = false;
      const str = this.searchBox;
      let checkCount = 0;

      let res = str.split(" ");

      res = res.map((element) => {
        const temp = element.split(":", 2);
        if (temp.length > 1) return { key: temp.shift(), value: temp };

        return { key: temp[0], value: "" };
      });

      res.forEach((element) => {
        this.checkKeyword(element);
        checkCount++;

        if (checkCount === res.length) {
          this.initialGetST(`desc`);
        }
      });
    },
    searchST: function () {
      const str = this.searchBox;
      self.alert_2 = false;
      let res = [];
      let currentStr = "";
      let inQuotes = false;
      let quoteType = null;

      for (let i = 0; i < str.length; i++) {
        if (str[i] === '"' && !inQuotes) {
          inQuotes = true;
          quoteType = str[i];
          continue;
        }

        if (str[i] === quoteType && inQuotes) {
          inQuotes = false;
          quoteType = null;
          continue;
        }

        if (str[i] === " " && !inQuotes) {
          res.push(currentStr);
          currentStr = "";
        } else {
          currentStr += str[i];
        }
      }

      if (currentStr !== "") {
        res.push(currentStr);
      }

      res = res.map((element) => {
        const temp = element.split(":");

        if (3 < temp.length) {
          if (
            temp[0] == "hostname" ||
            temp[0] == "domain" ||
            temp[0] == "city" ||
            temp[0] == "service" ||
            temp[0] == "product" ||
            temp[0] == "smb_dnsdomain" ||
            temp[0] == "version" ||
            temp[0] == "script" ||
            temp[0] == "banner" ||
            temp[0] == "cookie" ||
            temp[0] == "files" ||
            temp[0] == "httptitle" ||
            temp[0] == "smb_fqdn" ||
            temp[0] == "os" ||
            temp[0] == "devtype" ||
            temp[0] == "cpe" ||
            temp[0] == "webfiles" ||
            temp[0] == "asname" ||
            temp[0] == "tcategory" ||
            temp[0] == "tsource" ||
            temp[0] == "cve"
          ) {
            if (temp.includes('"${temp}"')) {
              // console.log("success")
              return {
                key: temp.shift(),
                value: temp[0] + ":" + temp[1] + ":" + temp[2] + "*",
              };
            }
            if (temp[0].includes(";")) {
              this.alert_2 = true;
            }
            if (temp[1].includes(";")) {
              this.alert_2 = true;
            }

            if (temp.includes("'")) {
              this.alert_3 = true;
            }
            return {
              key: temp.shift(),
              value: temp[0] + ":" + temp[1] + ":" + temp[2] + "*",
            };
          } else {
            if (temp.includes('"${temp}"')) {
              // console.log("success")
              return {
                key: temp.shift(),
                value: temp[0] + ":" + temp[1] + ":" + temp[2] + "*",
              };
            }
            if (temp.includes("'")) {
              this.alert_3 = true;
            }
            return {
              key: temp.shift(),
              value: temp[0] + ":" + temp[1] + ":" + temp[2],
            };
          }
        }
        if (2 < temp.length) {
          if (
            temp[0] == "hostname" ||
            temp[0] == "domain" ||
            temp[0] == "city" ||
            temp[0] == "service" ||
            temp[0] == "product" ||
            temp[0] == "smb_dnsdomain" ||
            temp[0] == "version" ||
            temp[0] == "script" ||
            temp[0] == "banner" ||
            temp[0] == "cookie" ||
            temp[0] == "files" ||
            temp[0] == "httptitle" ||
            temp[0] == "smb_fqdn" ||
            temp[0] == "os" ||
            temp[0] == "devtype" ||
            temp[0] == "cpe" ||
            temp[0] == "webfiles" ||
            temp[0] == "asname" ||
            temp[0] == "tcategory" ||
            temp[0] == "tsource" ||
            temp[0] == "cve"
          ) {
            if (temp.includes('"${temp}"')) {
              // console.log("success")
              return {
                key: temp.shift(),
                value: temp[0] + ":" + temp[1] + ":" + temp[2] + "*",
              };
            }
            if (temp[0].includes(";")) {
              this.alert_2 = true;
            }
            if (temp[1].includes(";")) {
              this.alert_2 = true;
            }

            if (temp.includes("'")) {
              this.alert_3 = true;
            }
            return { key: temp.shift(), value: temp[0] + ":" + temp[1] + "*" };
          } else {
            if (temp.includes('"${temp}"')) {
              // console.log("success")
              return {
                key: temp.shift(),
                value: temp[0] + ":" + temp[1] + ":" + temp[2] + "*",
              };
            }
            if (temp.includes("'")) {
              this.alert_3 = true;
            }
            return { key: temp.shift(), value: temp[0] + ":" + temp[1] };
          }
        }
        if (0 < temp.length) {
          if (
            temp[0] == "hostname" ||
            temp[0] == "domain" ||
            temp[0] == "city" ||
            temp[0] == "service" ||
            temp[0] == "product" ||
            temp[0] == "smb_dnsdomain" ||
            temp[0] == "version" ||
            temp[0] == "script" ||
            temp[0] == "banner" ||
            temp[0] == "cookie" ||
            temp[0] == "files" ||
            temp[0] == "httptitle" ||
            temp[0] == "smb_fqdn" ||
            temp[0] == "os" ||
            temp[0] == "devtype" ||
            temp[0] == "cpe" ||
            temp[0] == "webfiles" ||
            temp[0] == "asname" ||
            temp[0] == "tcategory" ||
            temp[0] == "tsource" ||
            temp[0] == "cve"
          ) {
            if (temp.includes(this.ref$temp)) {
              // console.log("success")
              return {
                key: temp.shift(),
                value: temp[0] + ":" + temp[1] + ":" + temp[2] + "*",
              };
            }

            if (temp.includes('"')) {
              this.alert_3 = true;
            }
            if (temp.includes("'")) {
              this.alert_3 = true;
            }
            if (temp[0].includes(";")) {
              this.alert_2 = true;
            }
            if (temp[1].includes(";")) {
              this.alert_2 = true;
            }
            return { key: temp.shift(), value: temp[0] + "*" };
          } else {
            if (temp.includes('"${temp}"')) {
              // console.log("success")
              return {
                key: temp.shift(),
                value: temp[0] + ":" + temp[1] + ":" + temp[2] + "*",
              };
            }
            if (temp[0].includes(";")) {
              this.alert_2 = true;
            }

            if (temp.includes('"')) {
              this.alert_3 = true;
            }
            if (temp.includes("'")) {
              this.alert_3 = true;
            }
            return { key: temp.shift(), value: temp[0] };
          }
        }
        if (-1 < temp.length) {
          return { key: temp.shift(), value: temp };
        }

        return { key: temp[0], value: "" };
      });

      return res;
    },
    splitOnce: function (s, on, first = undefined, rest = undefined) {
      [first, ...rest] = s.split(on);
      return [first, rest.length > 0 ? rest.join(on) : null];
    },
    checkKeyword: function (keyword) {
      if (search[keyword.key]) {
        console.log(
          `${keyword.key} query should be added with the value: ${keyword.value}`
        );
      } else {
        console.warn(`Keyword ${keyword.key} not found in querylist`);
        this.alert = true;
      }
    },
  },
  mounted() {
    if (this.$route.params.commandKey) {
      let params = this.$route.params;
      this.searchBox = `${params.commandKey}:${params.commandValue}`;
      this.initialGetST();
    }
    //  else {
    //    this.initialGetST();
    //  }
  },
  computed: {
    // isSearchBoxEmpty() {
    //   return this.searchBox === "";
    // },
    displayDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case `xs`:
          return true;
        case `sm`:
          return true;
        default:
          return false;
      }
    },
    params() {
      if (this.$route.params) {
        return this.$route.params;
      }
      return null;
    },
    disableHostScroll() {
      if (this.totalHosts > 10) {
        return false;
      }
      return true;
    },
    hideVuln() {
      if (this.selectedHost["xml-nmap"]) {
        if (
          this.selectedHost["xml-nmap"].host[0].ports &&
          this.selectedHost["xml-nmap"].host[0].ports[0]
        ) {
          return false;
        }
      }

      return true;
    },
  },
  watch: {
    params: function (value) {
      if (value != null) {
        this.searchBox = `${this.params.commandKey}:${this.params.commandValue}`;
        this.searchST();
      }
    },
  },
};
</script>

<style scoped>
.v-text-field /deep/ .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  cursor: pointer;
}

.v-list--dense /deep/ .v-list-item .v-list-item__title {
  font-size: 0.89rem;
}

.v-list--dense /deep/ .v-list-item {
  min-height: 35px;
}

.card-section {
  height: 86.5vh;
  overflow-y: auto;
}
.detail-section {
  height: 87vh;
  overflow-y: auto;
}

.v-list-item {
  padding: 0 12px;
}

.whiteBtn{
  filter: invert(96%) sepia(4%) saturate(17%) hue-rotate(186deg) brightness(105%) contrast(105%);
}
</style>
